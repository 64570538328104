
// Libraries
import * as React from 'react'

// Components
import Layout from '../../../components/layout'
import Seo from '../../../components/seo'
import DuoSection from '../../../components/duoSection'
import Button from "../../../components/button"

class RegisterCarerImportedSuccessPage extends React.Component {
	render() {
		return <Layout className="page--success nav-blue-half no-footer page-imported-success">
			<Seo title="Register Carer Success" />
			<DuoSection success>
				<div>
					<div className="content-wrap">
						<h1>Success!</h1>
						<p>Welcome to Concerts for Carers.</p>
						<p><Button to="/events" hollow>Explore Events</Button></p>
					</div>
				</div>
				<div>
				</div>
			</DuoSection>
		</Layout>
	}
}

export default RegisterCarerImportedSuccessPage
